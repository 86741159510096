import DytePlugin from "@dytesdk/plugin-sdk";
import React, { useEffect, useState } from 'react';
import "./App.css";
import { generateMyInterviewDocURL } from "./utils/generateMyInterviewDocURL";

function App() {
  const [myInterviewDocURL, setMyInterviewDocURL] = useState(null);
  console.log("intialising myinterviewdoc plugin");

  useEffect(()=>{
    intitiatedPluginFn();
    setTimeout(()=>{
      if(!myInterviewDocURL){
        const myInterviewDocURL = generateMyInterviewDocURL();
        setMyInterviewDocURL(myInterviewDocURL);
      }
    },5000)
  },[])

  const intitiatedPluginFn = async () => {
    const intitiatedPlugin = await DytePlugin.init({ authToken: "7ca599023f424c484ad3", ready:false });
    console.log("intialised myinterviewdoc plugin with false ready");
    window.dytePlugin = intitiatedPlugin;
    console.log("pluginData Listner Adding");
    intitiatedPlugin.addListener('pluginData', (response) => {
      console.log("Received response from Meeting in Plugin: ", response.data);
      let myInterviewDocEmbeddableURL = createMyInterviewDocEmbedURL(response.data.myInterviewDocLink);
      setMyInterviewDocURL(myInterviewDocEmbeddableURL)
      response.myInterviewDocEmbeddableURL = myInterviewDocEmbeddableURL;
    })
    console.log("pluginData Listner Added");
    intitiatedPlugin.ready();
    console.log("ready function calling")
    // intitiatedPlugin.emit('intializedMyInterviewDocPlugin');
    intitiatedPlugin.emitToMeeting('intializedMyInterviewDocPlugin');
 }

 const createMyInterviewDocEmbedURL = (myInterviewDocEmbeddableURL) => {
   return myInterviewDocEmbeddableURL;
 }

  return (
    <>
    {
      myInterviewDocURL ? <iframe src={myInterviewDocURL} height="100%" width="100%" className="plugin-myinterviewdoc" title="Iframe Example"></iframe> : <div>loading MyInterviewDoc...</div>
    }
    </>
  );
}

export default App;
